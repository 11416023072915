import React from 'react';
import './LoaderPage.scss';
//IMGS
import { ReactComponent as SIGLE } from "../../_imgs/SIGLE_WHITE.svg";

class LoaderPage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    /** Display the SIGLE */
    SIGLE() {
        return (
            <div className="logo">
                <SIGLE width="100" height="50" />
            </div>
        );
    }

    render() {
        return (
            <div className="loaderPage">
                {this.SIGLE()}
            </div>
        );
    }

   
}

export default LoaderPage;


