import { collection, getDocs } from "firebase/firestore"; 
import { db, storage } from './FirebaseCloudService';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

class ApiService{

   //-----------------------------------------------//
   //------------------- GET -----------------------//
   //-----------------------------------------------//

   /* Get all projects */
   async getAllProjects(){
      let projects = [];

      const querySnapshot = await getDocs(collection(db, "projects"));
      querySnapshot.forEach(async (doc) => {
         let id = doc.id;

         let project = {};
         project = doc.data();

         let cover = "";
         let imgs = [];

         cover = await this.loadCover(id);  //get cover
         imgs  = await this.loadImgs(id);  //get imgs

         project['cover'] = cover;
         project['imgs'] = imgs;

         projects.push(project);
      });
      projects.sort((a, b) => (a["id"] > b["id"]) ? 1 : -1)

      return projects;
   }

   /* load the cover */
   async loadCover(prjId){
      
      let cover = [];
      const listRefCover = ref(storage, 'covers/'+prjId);
      
      listAll(listRefCover).then((res) => {
         res.items.forEach( async (itemRef) => {
            getDownloadURL(itemRef).then((url) => {
               cover.push(url)
            });
         });
      }).catch((error) =>  console.error(error));
      return cover;
   }

   /* load the imgs */
   async loadImgs(prjId){
      let imgs = [];
      let id = 0;
      const listRef = ref(storage, 'projects/'+prjId);
      listAll(listRef).then((res) => {
         res.items.forEach( async (itemRef) => {
            let img = {"id" : id, "url" : "", "name" : itemRef.name};
            getDownloadURL(itemRef).then((url) => {
               img["url"] = url;
               imgs.push(img);
            });
            id++;
         });
      }).catch((error) =>  console.error(error));

      imgs.sort((a, b) => (a["id"] > b["id"]) ? 1 : -1);

      return imgs;
   }
}

export default ApiService;