import React from 'react';
import './HomePage.scss';

//COMPONENTS
import _HERO    from "../../Components/_HERO/_HERO";
import _ABOUT   from "../../Components/_ABOUT/_ABOUT";
import _CONTACT from "../../Components/_CONTACT/_CONTACT";
import _WORK    from "../../Components/_WORK/_WORK";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            langSelected : 0
        };

        this.targetContactsRef = React.createRef();
    }

    componentDidMount() {
        // let lang;
        // let selectedLangStorage = localStorage.getItem('langSelected');

        // if(selectedLangStorage === undefined || selectedLangStorage === null ){
        //     localStorage.setItem('langSelected', 0);
        //     lang = 0;
        // }
        // else lang = selectedLangStorage
        
        // this.setState({
        //     langSelected : lang,
        // });
	}

    /** Select the language of the website */
    _onSelectLang(langID){
        this.setState({
            langSelected : langID
        });
        localStorage.setItem('langSelected', langID);
    }

    /** Scroll to Contact section */
    _onScroll(){
        console.log("SROLL !");

        if (this.targetContactsRef && this.targetContactsRef.current) {
            this.targetContactsRef.current.scrollIntoView({ behavior: 'smooth'});
        }
    }

     //------------------------------------------------------------//
    //------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

    render() {
        let {langSelected} = this.state;
        let {projects} = this.props;

        return (
            <>
                <div className='blackscreen'></div>
                <div className='home disableSelection'>
                    <_HERO    lang={langSelected} onSelectLang={(langID) => this._onSelectLang(langID)} onScroll={() => this._onScroll()}/>
                    <_ABOUT   lang={langSelected} />
                    <_WORK    lang={langSelected} projects={projects}/>
                    <_CONTACT lang={langSelected} ref={this.targetContactsRef} />
                </div>
            </>
        );
    }
}


export default HomePage;


