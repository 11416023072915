import React, { Component } from "react";
import './BTN_MINI.scss';

// export default function SPLINE_ITEM() {
class BTN_MINI extends Component{

    /** Select lang */
	_onSelectLang(selectedLang){
		this.props.onSelectType(selectedLang);
	}

    render() { 
        let {label, selected} = this.props;
		let className = "";
		selected ? className = "btnMini --selected" : className = "btnMini";

        return(
            <div className={className} onClick={() => this.props.onClick()}>
                {label}
            </div>
        );
    }
}
export default BTN_MINI;
