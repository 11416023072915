import React, { Component } from "react";
import Spline from '@splinetool/react-spline';
import './SPLINE_ITEM.scss';


// export default function SPLINE_ITEM() {
class SPLINE_ITEM extends Component{

  render() { 
    return(
      <div className="spline-container" >
        {/* <Spline className='spline-content' scene="https://prod.spline.design/0od1pXYmubFCzG1D/scene.splinecode" /> */}
        <Spline className='spline-content' scene="https://prod.spline.design/FY7GMym-ssCmvecL/scene.splinecode" />
      </div>
    );
   
  }
}
export default SPLINE_ITEM;
