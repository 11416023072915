import React, { Component } from "react";
import './_ABOUT.scss';

import MARQUEE from "../MARQUEE/MARQUEE";
import DIVIDER from "../DIVIDER/DIVIDER";
import BTN_UNDERLINE from "../BTN_UNDERLINE/BTN_UNDERLINE";

import PROFILE_PIC from "../../_imgs/PROFILE_PIC.jpg";
import TEXTS from '../../_data/texts.json';
import TOOLS from "../../_data/tools.json";

class _ABOUT extends Component{

    render() { 
        let {lang} = this.props;

        return(
            <div className="about">
                <div className="marquee-about">
                    <MARQUEE name={TEXTS.titles.about[lang]} direction="right" color="white"/>
                </div>

                {/* Img */}
                <img src={PROFILE_PIC} className="about-img"/>

                {/* Data */}
                <div className="about-data">
                    
                    <div className="about-data-intro">{TEXTS.texts.intro[lang]}</div>
                   
                    {/* <DIVIDER /> */}

                    <div className="about-data-tools">
                        <h2>Outils</h2>

                        {/* debut boucle */}
                        {TOOLS.map((cat, i) =>{
                            return(
                                <div key={i} className="about-data-tools-item">
                                    <h3>{cat.title[lang]}</h3>
                                    <div className="about-data-tools-item-list">
                                        {cat.tools.map((tool, e) =>{
                                            return <BTN_UNDERLINE key={e} label={tool.name} url={tool.url}/>
                                        })}
                                    </div>
                                </div>   
                            );
                        })}
                    </div>
                </div>
            </div>
        );

    }
}
export default _ABOUT;
