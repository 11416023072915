import React, { Component } from "react";
import './_CONTACT.scss';

import MARQUEE from "../MARQUEE/MARQUEE";
import BTN_UNDERLINE from "../BTN_UNDERLINE/BTN_UNDERLINE";
import TEXTS from '../../_data/texts.json';

class _CONTACT extends Component{
    constructor(props) {
        super(props);
        this.state = {};
        this.contactsRef = React.createRef();
    }

    // Méthode publique pour effectuer le scroll
    scrollIntoView = () => {
        if (this.contactsRef && this.contactsRef.current) {
            this.contactsRef.current.scrollIntoView({ behavior: 'smooth'});
        }
    };

    render() { 
        let {lang} = this.props;

        return(
            <div className="contact" ref={this.contactsRef}>
                <div className="contact-marquee">
                    <MARQUEE name={TEXTS.titles.contact[lang]} direction="right" color="white"/>
                </div>
                <div className="contact-data">
                    <div className="contact-data-credits">
                        <div>{TEXTS.texts.footer_rights[lang]}</div>
                        <div>{TEXTS.texts.footer_author[lang]}</div>
                    </div>
                    <div className="contact-data-links">
                        <BTN_UNDERLINE label="a.bardonnet@gmail.com" url="mailto:a.bardonnet@gmail.com"/>
                        <BTN_UNDERLINE label="+33 6 06 47 36 52" url="tel:0606473652"/>
                        <BTN_UNDERLINE label="LinkedIn" url="https://www.linkedin.com/in/alexis-b-968a99172/"/>
                    </div>
                </div>

            </div>
        );
    }
}
export default _CONTACT;
