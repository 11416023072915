import DATA from "../_data/texts.json";


class StringService{

   getSelectedPrjType(prj, lang){
      let types = "";
      let i = 0;

      if(prj['types']){
         prj['types'].map((type) => {
            if(i < prj['types'].length-1) types = types+this.formatType(type, lang)+"  •  ";
            else if(i === prj['types'].length-1) types = types+this.formatType(type,lang);
            i++
         })
      }
      return types;
   }

   /** Render text */
   formatType(type, lang){
      let res =  "";
      if(type == 0){
         res = " "+DATA.skills.dg[lang]+" ";
      }else if (type == 1){
         res = " "+DATA.skills.webdesign[lang]+" ";
      }
      return res;
  }

}

export default StringService;