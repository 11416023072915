import React, { Component } from "react";
import './_WORK.scss';

import BADGE from "../../Components/BADGE/BADGE";
import MARQUEE  from "../MARQUEE/MARQUEE";
import BTN_MINI from '../BTN_MINI/BTN_MINI';
import BTN_ROUNDED from "../BTN_ROUNDED/BTN_ROUNDED";
import CAROUSEL from "../../Components/CAROUSEL/CAROUSEL";

import TEXTS    from '../../_data/texts.json';

//SERVICES
import StringService from "../../Services/StringService";
const stringService = new StringService();

class _WORK extends Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedType : 1,
            selectedProject : {},
            selectedImgProject : 0,
        };
    }

    // TODO Créer une référence pour le composant enfant
    carouselRef = React.createRef();

    componentDidMount() {
        let {projects} = this.props;

        projects.forEach( async (project) => {
            project["imgs"].sort((a, b) => (a["id"] > b["id"]) ? 1 : -1)
        });

        let lang;
        let selectedLangStorage = localStorage.getItem('selectedLang');

        if(selectedLangStorage === undefined || selectedLangStorage === null ){
            localStorage.setItem('selectedLang', 0);
            lang = 0;
        }
        else lang = selectedLangStorage
        
        this.setState({
            selectedLang    : lang,
            selectedProject : projects[0],
            selectedPic     : projects[0]['imgs'][0]
        });
	}

     //------------------------------------------------------------//
    //------------------------- SELECT ---------------------------//
   //------------------------------------------------------------//

    /** Selected type filter */
    _onSelectType(typeNbr){
        this.setState({ selectedType : typeNbr });
        this.carouselRef.current.refreshCarousel();
    }
    
    /** Selected project */
    _onSelectProject(prj){
        this.setState({ 
            selectedProject : prj,
            selectedImgProject : 0
         });
    }

    /** Selected img project */
    _onSelectProjectImg(id){
        this.setState({ selectedImgProject : id });
    }


     //------------------------------------------------------------//
    //------------------------ DISPLAY ---------------------------//
   //------------------------------------------------------------//

    /** Display the selected project badges */
    _displayTypes(selectedProject, selectedLang){
        let types = "";
        types = stringService.getSelectedPrjType(selectedProject, selectedLang);
        return types;
    }

    /** Display the selected project badges */
    _displayBadges(selectedProject){
        if(selectedProject['softwares']){
            return selectedProject['softwares'].map((soft) => {
                return(
                    <BADGE key={soft} type={soft}/>
                );
            })
        }
    }

    /** Display the selected project */
    _displaySelectedProject(selectedProject,selectedImgProject, lang){
        if(selectedProject !== undefined && selectedProject['lang'] !== undefined){
            return(
                <div className="work-content-selected">
                    <h2>{selectedProject['lang'][lang]['name']}</h2>
                    <div className="work-content-selected-type">
                        <div className="work-content-selected-type-date">{selectedProject['date']}</div>
                        <div className="work-content-selected-type-date">/</div>
                        <div>{this._displayTypes(selectedProject, lang)}</div>
                    </div>
                    <div className="work-content-selected-badges">
                        {this._displayBadges(selectedProject)}
                    </div>
                    <div className="work-content-selected-text">{selectedProject['lang'][lang]['desc']}</div>

                    <div className="work-content-selected-photos">

                        {/* Main */}
                        <div className="work-content-selected-photos-main" style={{ backgroundImage: `url(${selectedProject['imgs'][selectedImgProject]["url"]})` }}></div>

                        {/* Others */}
                        {selectedProject['imgs'] && (
                            <div className="work-content-selected-photos-othersList">
                                {selectedProject['imgs'].map((img) => {
                                     let classCurrent = "";
                                     if(selectedProject['imgs'][selectedImgProject]["url"] === img["url"]) classCurrent=" --currentImg"; else classCurrent="";

                                    return(
                                        <div className={"work-content-selected-photos-othersList-other"+classCurrent} style={{ backgroundImage: `url(${img["url"]})`}}  onClick={() => this._onSelectProjectImg(img["id"])}></div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }

    render() { 
        let {lang, projects} = this.props;
        let {selectedType, selectedProject, selectedImgProject} = this.state;

        return(
            <div className="work">
                <div className="work-marquee">
                    <MARQUEE name={TEXTS.titles.work[lang]} direction="left" color="grey"/>
                </div>

                <div className="work-content">
                    {/* BTNS ACTIONS */}
                    <div className="work-content-btns">
                        <BTN_MINI label={TEXTS.skills.dg[lang]}        selected={selectedType===0} onClick={() => this._onSelectType(0)}/>
                        <BTN_MINI label={TEXTS.skills.webdesign[lang]} selected={selectedType===1} onClick={() => this._onSelectType(1)}/>
                    </div>

                    {/* CAROUSEL */}
                    <CAROUSEL 
                        ref             = {this.carouselRef} 
                        projects        = {projects} 
                        onSelect        = {(prj) => this._onSelectProject(prj)} 
                        selectedType    = {selectedType} 
                        selectedProject = {selectedProject} 
                        selectedLang    = {lang}
                    />
                   
                    {/* PRJCT SELECTED  */}
                    {this._displaySelectedProject(selectedProject, selectedImgProject, lang)}
                </div>
            </div>
        );
    }
}
export default _WORK;
