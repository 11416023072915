/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./PROJECT_ITEM.scss";

class PROJECT_ITEM extends Component {
	render(){
        let {label, img, currentPrj} = this.props;

        let classCurrent = "";
        if(currentPrj) classCurrent=" --currentPrj"; else classCurrent="";

		return(
            <div className={"project-item"} onClick={() => this.props.onClick()}>
                <div className="project-item-content">
                    <div className="project-item-content-hover">
                        <div className="project-item-content-hover-text">{label}</div>
                        <div className="project-item-content-hover-cover"></div>
                    </div>
                    <div className={"project-item-content-img"+classCurrent} style={{ backgroundImage: `url(${img})` }}></div>
                </div>
            </div>
		);
	}
}

export default PROJECT_ITEM;

