import React, { Component } from "react";
import './BTN_UNDERLINE.scss';

class BTN_UNDERLINE extends Component{
  render() { 
    let {label, url} = this.props;
    return(
      <a className="btn-underline" href={url} target="_blank">
        {label}
      </a>
    );
  }
}
export default BTN_UNDERLINE;
