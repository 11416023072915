import React, { Component } from "react";
import './_HERO.scss';

import LANG        from "../LANG/LANG";
import SPLINE_ITEM from "../SPLINE_ITEM/SPLINE_ITEM";
import BTN_ROUNDED from "../BTN_ROUNDED/BTN_ROUNDED";

import TEXTS from '../../_data/texts.json';


class _HERO extends Component{

    /** Scroll to contact */
    _goToContacts(){
        this.props.onScroll();
    }

    render() { 
        let {lang, onSelectLang} = this.props;
        return(
            <div className="hero">
                <SPLINE_ITEM></SPLINE_ITEM>
                <div className="hide"></div>
                <div className='hero-content'>

                    <LANG onSelectLang={onSelectLang} lang={lang} />

                    <div className='hero-content-bottom'>
                        <div>
                            <div>{TEXTS.skills.webdesign[lang]}</div>
                            <div>{TEXTS.skills.dg[lang]}</div>
                            <div>{TEXTS.skills["art-dir"][lang]}</div>
                        </div>
                        <BTN_ROUNDED label="CONTACT" onClick={() => this._goToContacts()}/>
                    </div>
                </div>
            </div>
        );

    }
}
export default _HERO;
