/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./LANG.scss";
//DATA
// import DATA from "../../texts.json";

class LANG extends Component {
	constructor(props) {
        super(props);
        this.state = {
			style0 : "",
			style1 : ""
        };
    }

	componentDidMount() {
        let lang;
        let selectedLangStorage = localStorage.getItem('langSelected');

        if(selectedLangStorage === undefined || selectedLangStorage === null ){
            localStorage.setItem('langSelected', 0);
            lang = 0;
        }
        else lang = selectedLangStorage;
		
		this.props.onSelectLang(lang);

		this.setState({
			style0 : lang == 0 ? "lang-selected" : "",
			style1 : lang == 1 ? "lang-selected" : "",
		})
	}

	/** Select lang */
	_onSelectLang(selectedLang){
		this.props.onSelectLang(selectedLang);
		this.setState({
			style0 : selectedLang == 0 ? "lang-selected" : "",
			style1 : selectedLang == 1 ? "lang-selected" : "",
		})
	}


	render(){
    	let {style0, style1} = this.state;
		return(
			<div className="lang">
                <div className={style0} onClick={() => {this._onSelectLang(0)}}>FR</div>
                <div className={style1} onClick={() => {this._onSelectLang(1)}}>EN</div>
			</div>
		);
	}
}

export default LANG;

