/** Natives **/
import React, { Component, forwardRef } from "react";
/** Style **/
import "./CAROUSEL.scss";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import PROJECT_ITEM from "../../Components/PROJECT_ITEM/PROJECT_ITEM";

import { ReactComponent as ARROW_LEFT } from "../../_imgs/ARROW_LEFT.svg";
import { ReactComponent as ARROW_RIGHT } from "../../_imgs/ARROW_RIGHT.svg";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1130 },
        items: 4,
        slidesToSlide: 1,
        partialVisibilityGutter: 20
    },
    tablet: {
        breakpoint: { max: 1130, min: 850 }, 
        items: 2,
        slidesToSlide: 1,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: { max: 850, min: 0 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 40
    }
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    return <ARROW_LEFT className="carousel-button --left" onClick={() => onClick()}></ARROW_LEFT>;
};

const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    return <ARROW_RIGHT className="carousel-button --right" onClick={() => onClick()}></ARROW_RIGHT>;
};


class CAROUSEL extends Component {
    // Utiliser la méthode componentDidMount pour associer la fonction à la référence
    componentDidMount() {
        this.props.innerRef.current = {
            refreshCarousel: this._refreshCarousel,
        };
    }
    
    // Refresh the carousel
    _refreshCarousel = () => {
        this.Carousel.goToSlide(0,false);
    };
    
    /** Select the project */
    _selectProject(project){
        this.props.onSelect(project);
    }

    /** Display each projects */
    _displayProjects(projects, selectedType, selectedLang, selectedProject){
        return projects.map((project) => {
            if(project["types"].indexOf(selectedType) >= 0){

                let currentPrj = false;
                if(selectedProject.id === project.id) currentPrj=true; else currentPrj=false;

                return <PROJECT_ITEM    
                            key        = {project.id} 
                            label      = {project['lang'][selectedLang]['name']} 
                            img        = {project['cover'][0]} 
                            onClick    = {() => this._selectProject(project)} 
                            carousel   = {true}
                            currentPrj = {currentPrj}
                        />
            }
        })
    }

	render(){
        let {projects, selectedType, selectedLang, selectedProject} = this.props;

        return(
            <Carousel 
                ref                      = {(el) => (this.Carousel = el)}
                responsive               = {responsive}
                // autoPlay                 = {true}
                swipeable                = {true}
                draggable                = {true}
                infinite                 = {true}
                containerClass           = "carousel-container"
                itemClass                = "carousel-item"
                keyBoardControl          = {true}
                arrows                   = {true} 
                renderButtonGroupOutside = {true} 
                customLeftArrow          = {<CustomLeftArrow />}
                customRightArrow         = {<CustomRightArrow />}
                centerMode               = {true}
            >
                {projects && (this._displayProjects(projects, selectedType, selectedLang, selectedProject))}
            </Carousel>
        );
	}

}
// Utiliser le HOC forwardRef pour passer la référence au composant enfant
const CarouselWithRef = forwardRef((props, ref) => (
    <CAROUSEL {...props} innerRef={ref} />
));

export default CarouselWithRef;

