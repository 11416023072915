import React, { Component } from "react";
import './BTN_ROUNDED.scss';
import { ReactComponent as ARROW_DOWN } from "../../_imgs/ARROW_DOWN.svg";

// export default function SPLINE_ITEM() {
class BTN_ROUNDED extends Component{
  render() { 
    let {label} = this.props;

    return(
      <div className="btnRounded" onClick={() => this.props.onClick()}>
        {label}
        <ARROW_DOWN/>
      </div>
    );
  }
}
export default BTN_ROUNDED;
