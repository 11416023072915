/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./MARQUEE.scss";
//PLUGINS
import Marquee from "react-fast-marquee";

class MARQUEE extends Component {

	render(){
        let {name, direction, speed, color} = this.props;
        return(
            <Marquee direction={direction} speed={speed} autoFill={true} className="marquee">
                <h1 className={"marquee-"+color} >{"\u00A0"+name+"\u00A0"}</h1>
                <h1 className={"marquee-"+color+" arrow"}>{direction == "left" ? "←" : "→"}</h1>
            </Marquee>
        );
    }
}

export default MARQUEE;

